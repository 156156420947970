<template>
  <div class="single-product-element">
    <div class="spe-inner" v-if="product && product.status !== 2">
      <div class="spe-upper-container">
        <div class="spe-img">
          <img :src="this.getImageUrl()" alt="product_image" @error="getPlaceHolderUrl"/>
        </div>
        <div class="spe-upper-text">
          <div class="spe-name">
            {{ product.name }}
          </div>
<!--          <div class="spe-price-validity">-->
<!--            {{ $t("product.price_validity") }} {{ price_validity }}-->
<!--          </div>-->
          <div class="spe-price_group" v-if="price_group!==''">
            {{ $t("product.price_group") }} {{ this.price_group }}
          </div>
          <div class="spe-catalog">
            {{ $t("product.catalog") }} {{ catalog_page }}
          </div>
          <div class="spe-price">
            {{ this.price }} <span class="price_add_text" v-if="is_real_price_price" >{{$t("product.price_add_text")}}</span>
          </div>

        </div>
      </div>
      <div class="spe-lower-container">
        <div class="spe-shipping">
          <div class="spe-shipping-icon">
            <img src="@/assets/img/shipping-icon.svg" alt="lupe" />
          </div>
          <div class="spe-shipping-container">
            <div class="spe-upper-shipping-container">
              <div class="spe-upper-shipping-text-container">
                <div class="spe-shipping_costs">
                  {{ $t("product.shipping_costs") }} {{ this.shipping_cost }}
                </div>
                <div class="spe-shipping_costs_at" v-if="this.$t('config.store_code') === 'store_de' && this.shipping_cost_at">
                  {{ $t("product.shipping_costs_at") }} {{ this.shipping_cost_at }}
                </div>
              </div>
              <div class="spe-shipping-icon" v-if="shipping_img_name">
                <img src="@/assets/img/shipping_groups/Stueckgut.jpg" alt="lupe" v-if="shipping_img_name == 'Stueckgut'" />
                <img src="@/assets/img/shipping_groups/Paketversand.jpg" alt="lupe" v-if="shipping_img_name == 'Paketversand'" />
                <img src="@/assets/img/shipping_groups/Lademeter3m.jpg" alt="lupe" v-if="shipping_img_name == 'Lademeter3m'" />
                <img src="@/assets/img/shipping_groups/Lademeter6m.jpg" alt="lupe" v-if="shipping_img_name == 'Lademeter6m'" />
                <img src="@/assets/img/shipping_groups/Lademeter6mplus.jpg" alt="lupe" v-if="shipping_img_name == 'Lademeter6mplus'" />
              </div>

            </div>

            <div class="spe-shipping_additional">
              {{ $t("product.shipping_additional_text") }}
              <a target="_blank" :href="$t('product.shipping_table_link')">
                {{ $t('product.here') }}
              </a>
            </div>
          </div>


        </div>
        <div class="spe-delivery_date" v-if="replace_shipping">
          {{ $t('product.delivery_date_without_add') }} {{ delivery_time }}
        </div>
        <div class="spe-delivery_date" v-else>
          {{ $t('product.delivery_date') }} {{ delivery_time }}
        </div>
        <div class="spe-short_description" v-html="short_description">
        </div>


        <div class="spe-sku">
          <strong>{{ $t("SKU") }}:</strong> {{ product.sku }}
        </div>
      </div>
      <div class="product-add-to-cart-button" v-if=" is_real_price_price && cartAllowed">
        <div class="v-button v-primary v-centered">
          <a href="#addtocart" @click.prevent="addProductToCart">
            {{$t("cart.addToCartButton")}}
          </a>
        </div>
      </div>

      <div class="spe-additional-container">
        <div class="v-button v-primary v-centered">
          <a v-b-toggle.collapse-1 class="collapsed">
            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
            <font-awesome-icon icon="chevron-up"></font-awesome-icon>
            {{ $t("product.product_details") }}
          </a>
          <b-collapse id="collapse-1" class="mt-2">
            <div class="spe-details" v-html="attributes['description']">
            </div>
          </b-collapse>
        </div>
      </div>

    </div>
    <div v-else class="product-not-found-error">
      {{ $t("product.sku_not_found") }}
    </div>
  </div>
</template>

<script>

import apiConfig from "@/config/apiConfig";
import ProductService from "@/services/product.service";
import CartService from "@/services/cart.service";
import { useToast } from "vue-toastification";
export default {
  name: "ProductDetailElement",
  props: ["product"],
  data() {
    return {
      media_url: apiConfig.getMediaUrl(),
      short_description: "",
      price: ProductService.toCurrency(0),
      price_validity: "",
      catalog_page: "",
      shipping_cost: "",
      shipping_cost_at: "",
      delivery_time: "",
      price_group: "",
      attributes: [],
      is_real_price_price: true,
      is_real_price: true,
      is_real_price_at: true,
      qty: 1,
      replace_shipping: false,
      shipping_group_id:null,
      shipping_group_string: false,
      shipping_img_name: false,
      is_in_inquiry_cat:false
    }
  },
  async created() {
    this.updateAppAttributes();
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    cartAllowed() {
      return !this.$store.state.customer.noCart;
    },
  },
  methods: {
    addProductToCart(){
      let quote_id = this.$store.state.cart.cart.id
      let cartItem = {
        "sku": this.product.sku,
        "qty": this.qty,
        "quote_id": quote_id
      }
      console.log(cartItem);
      CartService.addItemToCart(cartItem).then(
          () => {
            this.emitter.emit('changedCart', {'changedCart': true})
            console.log("toast success");
            this.toast.success(this.$i18n.t("cart.addToCartSuccessMsg"));
          },
          error => {
            this.toast.error(error.message);
          }
      )

    },
    getImageUrl() {
      let img_url = this.media_url;
      let found = false;
      if (this.product) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(this.product.custom_attributes)) {
          this.attributes[value.attribute_code] = value.value;
          if (value.attribute_code === 'image') {
            img_url = img_url + "" + value.value
            found = true
          }
          if (value.attribute_code === 'short_description') {
            this.short_description = value.value
          }
        }
        if (found) {
          return img_url;
        } else {
          return apiConfig.getPlaceholderUrl();
        }
      }
    },
    getPlaceHolderUrl(event){
      event.target.src = apiConfig.getPlaceholderUrl();
    },
    updateAppAttributes() {
      if (this.product) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(this.product.custom_attributes)) {
          this.attributes[value.attribute_code] = value.value;
        }

        console.log("attributes");
        console.log(this.attributes);
        if (this.attributes['category_ids'].indexOf('393') !== -1) {
          console.log('is inquiry');
          this.is_in_inquiry_cat = true;
        } else {
          console.log('is not inquiry');
          this.is_in_inquiry_cat = false;
        }
        if (this.attributes['mptablerate_shipping_group']) {
          let $shipping_img_string = ProductService.getProductShippingGroupImgFileName(this.attributes['mptablerate_shipping_group']);
          if($shipping_img_string){
            this.shipping_img_name = $shipping_img_string;
          }
        }
        if (this.attributes['app_price']) {
          console.log("app_price");
          console.log(this.attributes['app_price']);
          if(this.attributes['app_price'] == 0) {
            this.price = this.$t('product.on_inquiry');
            this.is_real_price_price = false;
          } else {
            this.price = ProductService.toCurrency(this.attributes['app_price']);
          }
        } else {
          this.price = ProductService.toCurrency(this.product.price);
        }
        if(this.is_in_inquiry_cat){
          this.price = this.$t('product.on_inquiry');
          this.is_real_price_price = false;
        }
        if (this.attributes["app_price_available_till"]) {
          this.price_validity = ProductService.formatDate(this.attributes["app_price_available_till"]);
        }
        if (this.attributes["price_group"]) {
          this.price_group = this.attributes["price_group"];
        }
        if (this.attributes['app_delivery_price']) {
          if(this.attributes['app_delivery_price'] == 0) {
            this.shipping_cost = this.$t('product.on_inquiry');
            this.is_real_price = false;
          } else {
            this.shipping_cost = ProductService.toCurrency(this.attributes['app_delivery_price']);
          }
          if(this.shipping_img_name == 'Lademeter'){
            this.shipping_cost = this.$t('product.lademeter_shipping_cost');
          }
        }
        if (this.attributes['app_delivery_price_at']) {
          if(this.attributes['app_delivery_price_at'] == 0) {
            this.shipping_cost_at= this.$t('product.on_inquiry');
          } else {
            this.shipping_cost_at = ProductService.toCurrency(this.attributes['app_delivery_price_at']);
          }
          if(this.shipping_img_name == 'Lademeter'){
            this.shipping_cost_at = this.$t('product.lademeter_shipping_cost');
          }
        }
        if (this.attributes['catalog_page']) {
          this.catalog_page = this.attributes['catalog_page'];
        }
        if(process.env.CEMO_REPlACE_SHIPMENT == 1){
          this.delivery_time = process.env.CEMO_REPLACE_SHIPMENT_DE;
          this.replace_shipping = true;
        } else {
          if (this.attributes['delivery_time']) {
            this.delivery_time = this.attributes['delivery_time'];
          } 
        }

      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    product: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler() {
        this.updateAppAttributes();
      }
    }
  },
}
</script>
