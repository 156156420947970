export default {
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "Italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "Español": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "Swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
  "config": {
    "store_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store_de"])},
    "store_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1234567"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt", "@", "cemo-group.com"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://facebook.com"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/company/cemo-safe-storage/"])},
    "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/", "@", "cemo-group"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeiten"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück zur Startseite"])},
  "Call us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufen"])},
  "E-Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "Pricefinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisfinder"])},
  "SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
  "home": {
    "home-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Produktpreise und Lieferzeiten – auf einen Klick."])},
    "type-sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer eingeben"])},
    "start-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Starten"])},
    "invalid_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie mindestens 4 Ziffern ein."])}
  },
  "search-result": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Ergebnis zu:"])},
    "SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art-Nr."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])}
  },
  "product": {
    "price_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis gültig bis:"])},
    "shipping_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zzgl. Frachtkosten DE:"])},
    "shipping_costs_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zzgl. Frachtkosten AT:"])},
    "shipping_additional_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebenen Frachtkosten gelten für eine Lieferung innerhalb Deutschlands außer Inseln. Bei mehreren Produkten siehe Frachtkostentabelle"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferdatum: ca."])},
    "delivery_date_without_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferdatum:"])},
    "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maße"])},
    "product_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktdetails"])},
    "shipping_table_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.cemo.de/fracht/"])},
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalogseite: "])},
    "sku_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer unvollständig, bitte versuchen Sie es erneut."])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Produkte gefunden."])},
    "on_inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Anfrage"])},
    "price_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PG"])},
    "lademeter_shipping_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Berechnung der Versandkosten erfolgt im Warenkorb"])},
    "price_add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exkl. MwSt."])}
  },
  "contact": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEMO GmbH"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Backenländern 5"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["71384"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weinstadt"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
    "imprint_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.cemo.de/impressum"])},
    "privacy_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.cemo.de/datenschutz"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])}
  },
  "add_to_home_screen": {
    "banner_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App zum <br> HOME-Bildschirm <br> hinzufügen"])},
    "page_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung"])},
    "sub_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEMO-App zum HOME-Bildschirm hinzufügen"])},
    "ios": {
      "add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Safari-Browser verwenden"])}
    },
    "android": {
      "add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "cookies_disabled_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Cookies in Ihren Browser deaktivert. Diese App wird dann nicht richtig funktionieren, bitte aktivieren Sie die Cookies."])},
  "Base Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])},
  "account": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "logged-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wurden ausgeloggt."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
    "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetrete, Sie werden ausgeloggt."])},
    "priceFinderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier geht es zum Preisfinder"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "noAddressesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Rechnungs- und/oder Lieferadresse hinterlegt. Bitte loggen Sie sich auf https://shop.cemo.de ein und aktualisieren Sie die Daten. Danach können Sie hier in der App bestellen."])}
  },
  "cart": {
    "addToCartButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Warenkorb"])},
    "addToCartSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt zum Warenkorb hinzugefügt"])},
    "emptyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb ist leer"])},
    "checkout_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse"])}
  },
  "checkout": {
    "chooseAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier her senden"])},
    "continue_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Zahlung"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
    "shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandadresse"])},
    "shipping_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandmethoden"])},
    "shippingRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandmethode ist ein Pflichtfeld"])},
    "shippingFieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
    "paymentRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode ist ein Pflichtfeld"])},
    "agreementRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte akzeptieren!"])},
    "checkoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung erfolgreich!"])},
    "orderSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vielen Dank für Ihre Bestellung. Die Bestellnummer lautet: ", _interpolate(_named("orderNumber")), ". Sie erhalten in Kürze eine Bestellbestätigung per E-Mail."])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])}
  },
  "address": {
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strasse"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "sameAsBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gleiche Adresse wie Rechnung"])}
  }
}